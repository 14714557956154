<template>
  <div class="container-fluid mt-3">
    <back-button @click="goBack" />

    <div class="row mt-3">
      <div class="col-xl-12 order-xl-1">
        <card>
          <div slot="header" class="row align-items-center">
            <div class="col-8">
              <h3 class="mb-0">Edit Ticket</h3>
            </div>
            <!-- <div class="col-4 text-right">
              <base-button
                @click="goBack"
                type="button"
                class="btn btn-sm btn-primary"
                >Back to list</base-button
              >
            </div> -->
          </div>
          <div class="card-body">
            <form ref="profile_form" @submit.prevent="handleSubmit">
              <base-input
                label="Name *"
                v-model="ticket.name"
                name="Name"
                required
              />
              <validation-error :errors="apiValidationErrors.name" />
               <base-input
                label="Description *"
                v-model="ticket.description"
                name="Description"
                required
              />
              <validation-error :errors="apiValidationErrors.description" />
              <div class="my-4">
                <base-button
                  type="button"
                  class="btn btn-sm btn-primary"
                  native-type="submit"
                >
                  <i class="fa fa-save"/> Update Ticket
                </base-button>
              </div>
            </form>
          </div>
        </card>
      </div>
    </div>
  </div>
</template>
<script>
import formMixin from "@/mixins/form-mixin";
import ValidationError from "@/components/ValidationError.vue";
import BackButton from "@/components/BackButton.vue";

export default {
  layout: "DashboardLayout",

  mixins: [formMixin],

  components: {
    ValidationError,
    BackButton
  },

  data() {
    return {
      ticket: {
        type: "tickets",
        name: null,
        description: null,
      }
    };
  },
  created() {
    this.get();
  },
  methods: {
    async get() {
      try {
        const id = this.$route.params.id;
        await this.$store.dispatch("tickets/get", id).then(() =>
            this.ticket = this.$store.getters["tickets/ticket"]
        );
      } catch (error) {
        this.$notify({
          type: "danger",
          message: `Oops, something went wrong!`,
        });
        this.setApiValidation(error.response.data.errors);
      }
    },
    async handleSubmit() {
      if (this.$isDemo == 1 && ["1", "2"].includes(this.$route.params.id)) {
        this.$notify({
          type: "danger",
          message: "You are not allowed not change data of default tickets.",
        });
        return;
      }
      try {
        await this.$store.dispatch("tickets/update", this.ticket);

        this.$notify({
          type: "success",
          message: "Ticket updated successfully.",
        });
        this.goBack();
      } catch (error) {
        this.$notify({
          type: "danger",
          message: "Oops, something went wrong!",
        });
        this.setApiValidation(error.response.data.errors);
      }
    },  
    goBack() {
      this.$router.push({ name: "List Tickets" });
    },
  },
};
</script>
