<template>
  <div class="container-fluid mt-3">
    <div class="help-container mb-1">
      <el-drawer
        title="I am the title"
        :visible.sync="drawer"
        :with-header="false"
      >
        <h4 class="mt-5 mb-3">Visual Guide:</h4>
        <hr />
        <h4>Quick Guide:</h4>
        <ul>
          <li>Follow BookMeInScotty on: <a href="#">Twitter</a>.</li>
          <li>
            Read and subscribe to our newsletter
            <a href="#">Book Me In Scotty Blog</a>.
          </li>
          <li>Follow Book Me In Scotty on <a href="#">Instagram</a>.</li>
          <li>
            Follow Me In Scotty on
            <a href="https://www.facebook.com/creativetim">Facebook</a>.
          </li>
        </ul>
        <hr />

        <h4>Help Center:</h4>
        <a href="#">Read the official documentation here</a>
      </el-drawer>
    </div>

    <card class="no-border-card" body-classes="px-0 pb-1" footer-classes="pb-2">
      <template slot="header">
        <div class="row">
          <div class="col-xl-3 col-md-3">
            <base-input label="Month">
              <el-select
                v-model="month"
                filterable
                prepend-icon="fas fa-user"
                placeholder="Select..."
              >
                <el-option
                  v-for="val in months"
                  :key="val"
                  :label="val"
                  :value="val"
                >
                </el-option>
              </el-select>
            </base-input>
          </div>
          <div class="col-xl-3 col-md-3">
            <base-input label="Account">
              <el-select
                v-model="organizer"
                filterable
                prepend-icon="fas fa-user"
                placeholder="Select..."
              >
                <el-option
                  v-for="val in allOrganizers"
                  :key="val.id"
                  :label="val.name"
                  :value="val.id"
                >
                </el-option>
              </el-select>
            </base-input>
          </div>
          <div class="col-xl-3 col-md-3">
            <base-input label="Status">
              <el-select
                v-model="status"
                filterable
                clearable
                prepend-icon="fas fa-user"
                placeholder="Select..."
              >
                <el-option
                  v-for="status in allStatus"
                  :key="status"
                  :label="status"
                  :value="status"
                >
                </el-option>
              </el-select>
            </base-input>
          </div>
          <div class="col-xl-3 col-md-3 text-right filter-button">
            <base-button
              type="primary"
              size="sm"
              class="btn-base btn-icon"
              @click="getPayouts()"
              :disabled="!organizer||!month"
            >
              <span class="btn-inner--text">Filter</span>
            </base-button>
          </div>
        </div>
      </template>
    </card>
    <!--  Main Page -->
    <div>
      <div class="row">
        <div class="col-xl-4 col-md-4">
          <base-stats-card
            title="Total Tickets Sold"
            :sub-title="stats.totalGuests"
            :loading="loading"
            icon="fa fa-ticket-alt"
          >
          </base-stats-card>
        </div>

        <div class="col-xl-4 col-md-4">
          <base-stats-card
            title="Total Due Amount"
            type="gradient-info"
            :sub-title="stats.totalSales"
            :loading="loading"
            icon="fa fa-coins"
          >
          </base-stats-card>
        </div>

        <div class="col-xl-4 col-md-4">
          <base-stats-card
            title="Total Paid Amount"
            type="gradient-info"
            :sub-title="stats.totalBookings"
            :loading="loading"
            icon="fa fa-coins"
          >
          </base-stats-card>
        </div>
      </div>

      <card
        class="no-border-card"
        body-classes="px-0 pb-1"
        footer-classes="pb-2"
      >
        <template slot="header">
          <div class="row">
            <div class="col-6">
              <h3 class="mb-0">
                My Payments List
                <!--                <i class="fa fa-info-circle pointer-class" @click="drawer = true"/>-->
              </h3>
              <small class="text-muted">
                Showing {{ to }} of {{ total }} results

                <span v-if="selectedRows.length">
                  &nbsp; &nbsp; {{ selectedRows.length }} rows selected
                </span>
              </small>
            </div>
            <div class="col-6 text-right button-dropdown">
              <!-- <base-button
                  type="primary"
                  icon
                  size="sm"
                  class="btn-base btn-icon"
                  @click="csvExport()"
                  :disabled="attendees.length === 0"
                >
                  <span class="btn-inner--icon"
                    ><i class="fa fa-download"></i
                  ></span>
                  <span class="btn-inner--text">Download List</span>
                </base-button> -->

              <el-dropdown
                trigger="click"
                class="dropdown btn base-button btn-base btn-icon btn-icon btn-fab btn-primary btn-sm"
                :disabled="payouts.length === 0"
              >
                <span class="btn-inner--icon"
                  ><i class="fa fa-download" />
                  <span>Download list</span>
                </span>

                <el-dropdown-menu
                  class="dropdown-menu dropdown-menu-arrow show text-center"
                  slot="dropdown"
                >
                  <div style="display: flex; flex-direction: column">
                    <base-button
                      type="primary"
                      icon
                      size="sm"
                      class="btn-base btn-icon"
                      @click="dataExport('csv')"
                    >
                      <span class="btn-inner--icon"
                        ><i class="fa fa-download"></i
                      ></span>
                      <span class="btn-inner--text">as .CSV File</span>
                    </base-button>
                    <base-button
                      type="primary"
                      icon
                      size="sm"
                      class="btn-base btn-icon"
                      @click="dataExport('excel')"
                    >
                      <span class="btn-inner--icon"
                        ><i class="fa fa-download"></i
                      ></span>
                      <span class="btn-inner--text">as Excel File</span>
                    </base-button>
                  </div>
                </el-dropdown-menu>
              </el-dropdown>
            </div>
          </div>
        </template>
        <div>
          <!-- <div class="d-flex justify-content-center flex-wrap">
            <div class="col-10">
              <el-select
                v-model="selectedEventId"
                filterable
                placeholder="Please click to select an event..."
                class="dropdown-search"
              >
                <el-option
                  v-for="item in events"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </div>
          </div> -->
          <el-empty
            image="/img/placeholders/attendees-default.png"
            description="Please enter search criteria to list your Payments"
            v-if="!firstRQDone"
          />
          <div class="d-flex justify-content-center flex-wrap" v-if="firstRQDone">
            <div class="col-10">
              <base-input
                v-model="query"
                type="search"
                prepend-icon="fas fa-search"
                placeholder="Search with organizer or email..."
                clearable
              />
            </div>
          </div>
          <el-table
            class="table-responsive align-items-center table-flush"
            header-row-class-name="thead-light"
            :data="payouts"
            v-if="firstRQDone"
            @sort-change="sortChange"
          >
            <div slot="empty" v-if="loading">
              <img src="/img/loading.gif" style="height: 100px; width: 100px" />
            </div>

            <el-table-column
              label="Month"
              prop="created_at"
              min-width="150px"
              sortable
            >
              <template v-slot="{ row }">
                {{ row.created_at | humanTime}}
              </template>
            </el-table-column>
            <el-table-column
              label="Paid Amount"
              prop="paid_amount"
              min-width="200px"
              sortable
            />

            <el-table-column
              label="Due Amount"
              prop="gross_amount"
              min-width="200px"
              sortable
            />

            <el-table-column
              label="Bank Account"
              prop="vendor.bank_account"
              min-width="200px"
              sortable
            />

            <el-table-column
              label="Organizer"
              prop="vendor.name"
              min-width="170px"
              sortable
            />

            <el-table-column
              label="Email"
              prop="vendor.email"
              min-width="200px"
              sortable
            />
            <!--            <el-table-column label="Paid" prop="paid" min-width="150px" sortable>-->
            <!--              <template v-slot="{ row }">-->
            <!--                <badge class="badge-dot mr-4" type="">-->
            <!--                  <i-->
            <!--                    :class="`bg-${row.is_paid === 1 ? 'success' : 'danger'}`"-->
            <!--                  ></i>-->
            <!--                  <span class="status">{{-->
            <!--                    row.is_paid === 1 ? "Yes" : "No"-->
            <!--                  }}</span>-->
            <!--                </badge>-->
            <!--              </template>-->
            <!--            </el-table-column>-->

            <!-- <el-table-column
              label="Amount"
              prop="price"
              min-width="144px"
              sortable
            >
              <template v-slot="{ row }">
                <span class="text-center">
                  $ {{ getTotalBookingAmount(row) }}
                </span>
              </template>
            </el-table-column> -->

            <el-table-column label="Status" min-width="150px">
              <template v-slot="{ row }">
                <badge class="badge-dot mr-4" type="">
                  <i
                    :class="`bg-${
                      row.status === 'completed'
                        ? 'success'
                        : row.status === 'pending'
                        ? 'primary'
                        : row.status === 'failed'
                        ? 'warning': 'danger'
                    }`"
                  ></i>
                  <span class="status">{{
                    row.status === 'completed'
                        ? 'Completed'
                        : row.status === 'pending'
                        ? 'Pending'
                        : row.status === 'failed'
                        ? 'Failed': 'Incomplete'
                  }}</span>
                </badge>
              </template>
            </el-table-column>

            <el-table-column class-name="actions"
              label="Payment Receipt"
              min-width="200px"
              align="center"
            >
            <div slot-scope="{ row }" class="table-actions">
              <base-button
                      type="primary"
                      icon
                      size="sm"
                      class="btn-base btn-icon"
                      @click="getInvoice(row)"
                      disabled
                    >
                    <span class="btn-inner--text">Invoice</span>
                  </base-button>

                </div>
            </el-table-column>
            
              <el-table-column label="Actions" class-name="actions"
                              min-width="130px"
                              align="center">
                <div slot-scope="{ row }" class="table-actions">
                 
                  <el-tooltip content="Pay amount to organizer" placement="top">
                    <el-dropdown trigger="click" class="dropdown">
                    <span class="text-light ml-2">
                      <i class="fa fa-coins text-base mt-2"/>
                    </span>
                      <el-dropdown-menu
                          class="dropdown-menu dropdown-menu-arrow show text-center ml-3 mr-3"
                          slot="dropdown"
                      >

                        <div class="action-input">
                          <input                          
                          placeholder="Amount to pay"
                          v-model="row.payAmount"
                          name="amount-to-pay"               
                          type="number"
                          :min="0"
                          class="form-control"           
                        />
                          <base-button type="button"
                                       class="btn btn-sm btn-base ml-2 mb-2"
                                       @click="payAmount(row)"
                                       data-toggle="tooltip"
                                       style="cursor: pointer"
                                       :disabled="!row.payAmount"
                                       append
                          >
                            Pay
                          </base-button>
                        </div>
                      </el-dropdown-menu>
                    </el-dropdown>
                  </el-tooltip>

                </div>
              </el-table-column>
          </el-table>
        </div>
        <div
          v-if="firstRQDone"
          slot="footer"
          class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
        >
          <el-select
            class="select-primary pagination-select"
            v-model="pagination.perPage"
            placeholder="Per page"
            :disabled="attendees.length === 0"
          >
            <el-option
              class="select-primary"
              v-for="item in pagination.perPageOptions"
              :key="item"
              :label="item"
              :value="item"
            >
            </el-option>
          </el-select>
          <base-pagination
            class="pagination-no-border"
            v-model="pagination.currentPage"
            :per-page="pagination.perPage"
            :total="total"
          />
        </div>
      </card>
    </div>
  </div>
</template>
<script>
import { exportCsv } from "@/util/export-csv";
import { exportExcel } from "@/util/export-excel";

import {
  Table,
  TableColumn,
  DropdownMenu,
  DropdownItem,
  Dropdown,
  Tooltip,
  Select,
  Option,
  Button,
  Empty,
  Drawer,
} from "element-ui";
import swal from "sweetalert2";
import "sweetalert2/dist/sweetalert2.css";
import moment from "moment";

export default {
  layout: "DashboardLayout",

  components: {
    [Tooltip.name]: Tooltip,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu,
    [Select.name]: Select,
    [Option.name]: Option,
    [Button.name]: Button,
    [Empty.name]: Empty,
    [Drawer.name]: Drawer,
  },

  data() {
    return {
      drawer: false,
      attendees: [],
      events: [],
      selectedRows: [],
      query: null,
      sort: "created_at",
      selectedEventId: null,
      firstRQDone: false,
      total: 0,
      stats: {
        totalBookings: "0",
        totalSales: "0",
        totalGuests: "0",
      },
      allPayouts: [],
      loading: true,
      pagination: {
        perPage: 25,
        currentPage: 1,
        perPageOptions: [25, 50, 100],
      },
      allOrganizers: [],
      allStatus: ['pending','completed','failed','refund'],
      month: null,
      organizer: null,
      status: null,
      payouts: []
    };
  },

  computed: {
    user() {
      return this.$store.getters["profile/me"];
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    months() {
      //return 12 past months
      let months = [];
      for (let i = 11; i >= 0; i--) {
        months.unshift(moment().subtract(i, "months").format("MMMM YYYY"));
      }
      return months
    }
  },
  watch: {
    pagination: {
      handler: "getList",
      immediate: false,
      deep: true,
    },
    // selectedEventId: {
    //   handler: "getListDebounced",
    //   immediate: false,
    //   deep: true,
    // },
    attendees: {
      handler: "updateStats",
      immediate: false,
      deep: true,
    },
    query: {
      handler: "getListDebounced",
      immediate: false,
    },
  },
  mounted() {
    this.fetchOrganizers();
  },
  methods: {
    getListDebounced: _.debounce(function () {
      this.loading = true;

      this.getPayoutsFromSearch();
    }, 300),
    getDateFormat(month){
      let tempMonth = month.substring(0, month.length - 4)
      let tempYear = month.substring(month.length - 4, month.length)
      return  new Date(Date.parse(tempMonth + "1, " + tempYear));
    },

    // async getList() {
    //   let params = {
    //     ...(this.sort ? { sort: this.sort } : {}),
    //     filter: {
    //       ...(this.selectedEventId ? { item_id: this.selectedEventId } : {}),
    //     },
    //     page: {
    //       number: this.pagination.currentPage,
    //       size: this.pagination.perPage,
    //     },
    //     //  include: "items",
    //   };

    //   await this.$store.dispatch("attendees/list", params).then(() => {
    //     this.attendees = this.$store.getters["attendees/list"];
    //     this.total = this.$store.getters["attendees/listTotal"];
    //     this.firstRQDone = true;
    //   });

    //   this.loading = false;
    // },
    async payAmount (data) {
      const payAmount = Number(data.payAmount)
      delete data.payAmount;
      data.paid_amount = isNaN(payAmount) ? data.paid_amount : !data.paid_amount ? payAmount : data.paid_amount + payAmount;

      try {
        await this.$store.dispatch("reports/update", data);
        this.$notify({
          type: "success",
          message: "Amount updated successfully.",
        });
        this.getPayoutsFromSearch();

      } catch (error) {
        this.$notify({
          type: "danger",
          message: "Oops, something went wrong!",
        });
      }
    },
    async getPayouts () {      
      let tempDate = this.getDateFormat(this.month)
      let start_at = moment(tempDate).startOf('month').format('YYYY-MM-DD 00:00')
      let end_at = moment(tempDate).endOf('month').format('YYYY-MM-DD 23:59')
      let params = {
        // comment out temporally
        // ...(this.sort ? { sort: this.sort } : {}),
        filter: {
          ...(this.organizer ? { user_id: this.organizer } : {}),
          ...(start_at && end_at
              ? { created_at: [start_at, end_at] }
              : { created_at: [] }),
          ...(this.status ? { status: this.status } : {}),
        },
        page: {
          number: this.pagination.currentPage,
          size: this.pagination.perPage,
        },
        include: "vendors",
      };

      await this.$store.dispatch("reports/list", params).then(() => {
        this.payouts = this.$store.getters["reports/list"];
        this.total = this.$store.getters["reports/listTotal"];
        this.firstRQDone = true;
        this.previousSelectedMonth = JSON.parse(JSON.stringify(this.month));
        this.previousSelectedOrganizer = JSON.parse(JSON.stringify(this.organizer));
        this.previousSelectedStatus = JSON.parse(JSON.stringify(this.status));
      });

      this.loading = false;
    },
    async getPayoutsFromSearch () {
      let tempDate = this.getDateFormat(this.previousSelectedMonth)
      let start_at = moment(tempDate).startOf('month').format('YYYY-MM-DD 00:00')
      let end_at = moment(tempDate).endOf('month').format('YYYY-MM-DD 23:59')
      let params = {
        // comment out temporally
        // ...(this.sort ? { sort: this.sort } : {}),
        filter: {
          ...(this.previousSelectedOrganizer ? { user_id: this.previousSelectedOrganizer } : {}),
          ...(start_at && end_at
              ? { created_at: [start_at, end_at] }
              : { created_at: [] }),
          ...(this.previousSelectedStatus ? { status: this.previousSelectedStatus } : {}),
        },
        page: {
          number: this.pagination.currentPage,
          size: this.pagination.perPage,
        },
        ...(this.query ? { organizer: this.query } : {}),
        ...(this.query ? { email: this.query } : {}),

        include: "vendors",
      };

      await this.$store.dispatch("reports/list", params).then(() => {
        this.payouts = this.$store.getters["reports/list"];
        this.total = this.$store.getters["reports/listTotal"];
        this.firstRQDone = true;
      });

      this.loading = false;
    },
    async fetchOrganizers() {
      try {
        let params = {
          filter: {
            ...{ roles: "organizer" },
          },
          // page: {
          //   number: this.pagination.currentPage,
          //   size: this.pagination.perPage,
          // },
          include: "roles",
        };
        // fire a GET request to load the items
        await this.$store.dispatch("users/list", params).then(() => {
          this.allOrganizers = this.$store.getters["users/list"];
        });

        this.loading = false;
      } catch (error) {
        this.loading = false;

        // // Notification
        // this.$notify({
        //   type: "danger",
        //   message: "Oops, something went wrong with your account!",
        // });
        // this.setApiValidation(error.response.data.errors);
      }
    },
    async updateStats() {
      this.loading = true;

      let params = {
        filter: {
          ...(this.selectedEventId ? { item_id: this.selectedEventId } : {}),
        },
      };
      await this.$store.dispatch("reports/list", params).then(() => {
        this.allPayouts = this.$store.getters["reports/list"];
      });

      // compute stats
      let sumBookings = 0;
      let sumGuests = 0;
      let sumSales = 0;
      this.allPayouts.forEach((attendee) => {
        sumBookings++;

        sumGuests += attendee.quantity;
        sumSales += attendee.price * attendee.quantity;
      });

      this.stats.totalBookings = sumBookings.toString();
      this.stats.totalGuests = sumGuests.toString();
      this.stats.totalSales = "A$ " + sumSales.toString();

      this.loading = false;
    },
    async dataExport(fileType) {
      try {
        let tempDate = this.getDateFormat(this.previousSelectedMonth)
        let start_at = moment(tempDate).startOf('month').format('YYYY-MM-DD 00:00')
        let end_at = moment(tempDate).endOf('month').format('YYYY-MM-DD 23:59')
        let params = {
          filter: {
          ...(this.previousSelectedOrganizer ? { user_id: this.previousSelectedOrganizer } : {}),
          ...(start_at && end_at
              ? { created_at: [start_at, end_at] }
              : { created_at: [] }),
          ...(this.previousSelectedStatus ? { status: this.previousSelectedStatus } : {}),
        },
        include: "vendors"
        };

        await this.$store.dispatch("reports/list", params).then(() => {
          this.allPayouts = this.$store.getters["reports/list"];
        });

        const exportData = [];
        this.allPayouts.forEach((report) => {
          exportData.push({
            "MONTH": report.created_at,
            "PAID AMOUNT": report.paid_amount,
            "DUE AMOUNT": report.gross_amount,
            "BANK ACCOUNT": report.vendor.bank_account,
            "ORGANIZER": report.vendor.name,
            "EMAIL": report.email,
            "STATUS": report.status
          });
        });
        if (fileType === "csv") {
          exportCsv(exportData, "bookmeinscotty-payouts-list.csv");
        } else {
          exportExcel(exportData, "bookmeinscotty-payouts-list.xlsx");
        }
        this.loading = false;
      } catch (error) {
        console.log("Error", error);
        this.loading = false;
      }
    },
    async getInvoice () {
      try {
        let params = {
          filter: {
            ...(this.selectedEventId ? { item_id: this.selectedEventId } : {}),
          },
        };

        await this.$store.dispatch("attendees/list", params).then(() => {
          this.allPayouts = this.$store.getters["attendees/list"];
        });

        const exportData = [];
        this.allPayouts.forEach((attendee) => {
          exportData.push({
            "FULL NAME": attendee.name,
            "BOOKING NUMBER": attendee.ticket_number,
            EMAIL: attendee.email,
            PAID: attendee.is_paid === -1 ? "NO" : "YES",
            "BOOKING STATUS": attendee.is_paid === 1 ? "COMPLETED" : "PENDING",
            PRICE: attendee.price * attendee?.quantity,
            QUANTITY: attendee.quantity,
            "BOOKED AT": attendee.created_at,
            "EVENT ID": attendee.item_id,
          });
        });    
          exportExcel(exportData, "bookmeinscotty-guest-list.xlsx");
        this.loading = false;
      } catch (error) {
        console.log("Error", error);
        this.loading = false;
      }
    },
    addTicket() {
      this.$router.push({ name: "Add Ticket" });
    },
    sortChange({ prop, order }) {
      if (order === "descending") {
        this.sort = `-${prop}`;
      } else {
        this.sort = `${prop}`;
      }
      this.getPayoutsFromSearch();
    },
    // editTicket(row) {
    //   this.$router.push({
    //     name: "Edit Ticket",
    //     params: { id: row.id },
    //   });
    // },
    getTotalBookingAmount(booking) {
      return booking?.price * booking?.quantity;
    },
  },
};
</script>
