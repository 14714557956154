<template>
  <div>
    <base-header
      class="pb-6 content__title content__title--calendar"
      type="base"
    >
      <div class="row align-items-center py-4"></div>
    </base-header>
    <base-survey-component v-if="surveyId" :surveyId="surveyId" />
  </div>
</template>
<script>
import formMixin from "@/mixins/form-mixin";
import BaseSurveyComponent from "@/components/Surveys/BaseSurveyComponent.vue";

export default {
  layout: "DashboardLayout",
  mixins: [formMixin],
  components: {
    BaseSurveyComponent,
  },

  data() {
    return {
      surveyId: null,
    };
  },
  created() {
    this.surveyId = this.$route.params.uuid;
  },
};
</script>
