<template>
  <div style="overflow: hidden;">
    <base-header
      class="pb-6 content__title content__title--calendar"
      type="base"
    >
      <div class="row align-items-center py-4"></div>
    </base-header>

    <base-collections :isPublic="true"></base-collections>

    <div class="row justify-content-between mb-2">
      <div class="col-md-4">
        <div class="copyright text-left ml-3">
          <small class="text-muted"> Powered by </small>
          <a href="http://www.bookmeinscotty.com">
            <img
                src="/img/brand/bmis-default-full-logo_italic_bck.png"
                style="max-width: 27%; max-height: 2rem;"
            />©
          </a>
        </div>
      </div>
      <div class="col-md-3">
        <div class="copyright text-right">
          <small class="text-muted mr-4">© {{copyrightYear}} | All Rights Reserved </small>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BaseCollections from "@/components/Collections/BaseCollections.vue";
export default {
  layout: "PublicCollectionsPage",
  components: {
    BaseCollections,
  },
  data() {
    return {
      copyrightYear: new Date().getFullYear()
    };
  }
};
</script>
