/**
 * Simple export excel function that exports a passed array to an excel file
 * @param exportData
 * @param fileName
 */
import { utils, writeFileXLSX } from 'xlsx';
export function exportExcel(exportData, fileName = 'export.xlsx') {

      const dataToExport = utils.json_to_sheet(exportData) 
      const wb = utils.book_new() 
      utils.book_append_sheet(wb, dataToExport) 
      // export Excel file
      writeFileXLSX(wb, fileName)
}