<template>
  <div style="overflow: hidden;">
    <base-header class="pb-6 content__title content__title--calendar" type="base">
      <div class="row align-items-center py-4"></div>
    </base-header>

    <base-calendar :isPublic="true"></base-calendar>

<!-- Footer-->
    <div class="row justify-content-between mb-2">
      <div class="col-md-4">
        <div class="copyright text-left ml-3">
          <small class="text-muted"> Powered by </small>
          <a href="http://www.bookmeinscotty.com">
            <img
                src="/img/brand/bmis-default-full-logo.png"
                style="max-width: 27%; max-height: 2rem;"
                class="mr-1"
            />™
          </a>
        </div>
      </div>
      <div class="col-md-3">
        <div class="copyright text-right">
          <small class="text-muted mr-4">© {{copyrightYear}} | All Rights Reserved </small>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
// packages
import formMixin from "@/mixins/form-mixin";
// components
import BaseCalendar from "@/components/Calendar/BaseCalendar.vue";

export default {
  name: "PublicCalendar",
  components: {
    BaseCalendar
  },
  logo: {
    type: String,
    default: '/img/brand/' + process.env.VUE_APP_DEFAULT_FULL_LOGO,
    description: "Sidebar app logo",
  },
  mixins: [formMixin],
  data() { 
    return {      
      copyrightYear: new Date().getFullYear()
    };
  }
};
</script>

