<template>
  <div class="container-fluid mt-3">
    <back-button @click="goBack" />
    <base-survey-component v-if="surveyId" :surveyId="surveyId" />
  </div>
</template>
<script>
import formMixin from "@/mixins/form-mixin";
import BackButton from "@/components/BackButton.vue";
import BaseSurveyComponent from "@/components/Surveys/BaseSurveyComponent.vue";

export default {
  layout: "DashboardLayout",
  mixins: [formMixin],
  components: {
    BackButton,
    BaseSurveyComponent,
  },

  data() {
    return {
      surveyId: null,
    };
  },
  created() {
    this.surveyId = this.$route.params.id;
  },
  methods: {
    goBack() {
      this.$router.push({ name: "List Surveys" });
    },
  },
};
</script>
