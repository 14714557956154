<template>
  <div class="container-fluid mt-3">
    <back-button @click="goBack" />

    <div class="row mt-3">
      <div class="col-xl-12 order-xl-1">
        <card>
          <div slot="header" class="row align-items-center">
            <div class="col-8">
              <h3 class="mb-0">Edit Survey</h3>
            </div>
          </div>
          <div v-if="loading" class="row justify-content-center mt-2">
            <div slot="empty">
              <img src="/img/loading.gif" style="height: 100px; width: 100px" />
            </div>
          </div>
          <div id="surveyCreator" />
        </card>
      </div>
    </div>
  </div>
</template>
<script>
import formMixin from "@/mixins/form-mixin";
import BackButton from "@/components/BackButton.vue";
import { SurveyCreator } from "survey-creator-knockout";
import "survey-core/defaultV2.min.css";
import "survey-creator-core/survey-creator-core.min.css";
const creatorOptions = {
  showLogicTab: true,
  isAutoSave: true,
};
export default {
  layout: "DashboardLayout",

  mixins: [formMixin],

  components: {
    BackButton,
  },

  data() {
    return {
      survey: {},
      loading: false,
    };
  },
  created() {
    this.get();
  },
  methods: {
    async get() {
      try {
        this.loading = true;
        const id = this.$route.params.id;
        let params = {
          filter: {
            ...(this.user ? { user_id: this.user.id } : { user_id: 0 }),
          },
        };
        await this.$store
          .dispatch("surveys/get", { id, params })
          .then(() => (this.survey = this.$store.getters["surveys/survey"]));

        const creator = new SurveyCreator(creatorOptions);
        creator.text = JSON.stringify(this.survey.payload);
        creator.saveSurveyFunc = (saveNo, callback) => {
          this.saveSurveyJson(creator.JSON, saveNo);
        };
        creator.render("surveyCreator");
        this.loading = false;
      } catch (error) {
        this.$notify({
          type: "danger",
          message: `Oops, something went wrong!`,
        });
        this.setApiValidation(error.response.data.errors);
        this.loading = false;
      }
    },
    async saveSurveyJson(json, saveNo) {
      json.type = "surveys";
      if (!this.survey.id) {
        await this.$store.dispatch("surveys/add", json).then(() => {
          this.survey = this.$store.getters["surveys/survey"];
        });
      } else {
        json.id = this.survey.id;
        await this.$store.dispatch("surveys/update", json);
      }
    },
    goBack() {
      this.$router.push({ name: "List Surveys" });
    },
  },
};
</script>
