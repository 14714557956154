/**
 * Simple export csv function that exports a passed array to a csv file
 * @param exportData
 * @param fileName
 */
  export function exportCsv(exportData, fileName = 'export.csv') {
    let csvContent = "data:text/csv;charset=utf-8,";
    csvContent += [
      Object.keys(exportData[0]).join(";"),
      ...exportData.map((item) => Object.values(item).join(";")),
    ]
      .join("\n")
      .replace(/(^\[)|(\]$)/gm, "");

    const data = encodeURI(csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", data);
    link.setAttribute("download", fileName);
    document.body.appendChild(link); // Required for FF
    link.click();
  }